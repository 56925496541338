import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section" style={{backgroundColor: `#faf9f6`}}>
          <div className="container">
            <div className="content">
              <h1
                style={{
                  fontSize: `20px`
                }}
              >お問い合わせ</h1>
              <div
                style={{ margin: `30px 0` }}
              >
                <h2
                  style={{
                    fontSize: `18px`
                  }}
                >
                  毎日身につける下着を、もっと楽しめるものに。
                </h2>
                <div>こんにちは、ちーちょろすです。</div>
                <div>「下着の魔法使い」として活動を始め、もう１年が経過しました。</div>
                <div>初めましての方のために少し自己紹介をさせていただくと、下着の情報発信や下着を選ぶお手伝いをさせていただいている、元下着販売員です。</div>
                <div>
                  <h3
                    style={{
                      fontSize: `16px`,
                      marginTop: `10px`
                    }}
                  >
                  【こんなことをお仕事にしています】
                  </h3>
                  <div>私は下着の知識０で販売員になり、「もっと早く知りたかった！！」と思うことがたくさんありました。</div>
                  <div>なので、このサイト含め全てのSNS、パーソナルトレーニングでは「下着の知識を知るための場所」を作っています。</div>
                  <p>お仕事の詳しい内容はこちらをご覧ください。</p>
                  <ul>
                    <li>
                      <a
                        href="https://note.com/chiicho/n/nc0779f783a37"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        法人向け仕事内容
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://note.com/chiicho/n/n9706375341c0"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        個人向け仕事内容
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3
                    style={{
                      fontSize: `16px`,
                      marginTop: `10px`
                    }}
                  >
                  【私にしかできない、強み】
                  </h3>
                  <div>そして、販売員を辞めた理由は、
                  「ブランドや価格に囚われず、本当にぴったりの下着をオススメしたい」と思ったからです。</div>
                  <div>また、自分自身の知識０の時の不安や悩みに誰よりも寄り添いたいと思っています。</div>
                  <div>売り上げや人気商品を勧めるのではなく、自分が心から着けていただきたいと思う商品と女性を繋げられるように、お仕事をさせていただいております。</div>
                  <div>「ブランドに属していない」「誰よりもお客様の下着の悩みと向き合ってきた」この２つが私の強みです。</div>
                </div>
                <div>
                  <h3
                    style={{
                      fontSize: `16px`,
                      marginTop: `10px`
                    }}
                  >
                  【他に挑戦してみたいと思っているお仕事】
                  </h3>
                  <ul>
                    <li>販売員向けの下着の勉強会</li>
                    <li>下着関係のイベント</li>
                    <li>素敵な下着商品のPR</li>
                    <li>学生さん向け下着の講義</li>
                    <li>買えない試着会</li>
                  </ul>
                  <div>など</div>
                </div>
                <div>
                  <div>
                  少しでも女性が毎日身につけるものをもっと身近に感じられる場所作りや、イイモノを広めるための場所として、私を使っていただけたらと思います。
                  </div>
                </div>
              </div>
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    おなまえ
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    メールアドレス
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    お問い合わせ内容
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div
                  className="field"
                  style={{
                    textAlign: `right`
                  }}
                >
                  <button
                    className="button is-link"
                    type="submit"
                    style={{
                      width: `180px`,
                      maxWidth: `220px`,
                      padding: `18px auto 18px`,
                      fontSize: `18px`,
                      color: `black`,
                      fontWeight: `400`,
                      border: `1px solid #ccc`,
                      padding: `1.25reb 1rem`,
                      borderRadius: `1.5rem`,
                      boxShadow: `none`,
                      backgroundColor: `white`
                    }}
                  >
                    送信 ✈︎
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
